

const Footer = () => {
    return (
        <footer
         style={{
             color: 'rgba(191,191,191,0.38)'
         }}
        >
            <p>Auth: t_strdl - © 2024, All rights reserved</p>
        </footer>
    );
}

export default Footer