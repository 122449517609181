import {Button} from "primereact/button";
import Footer from "./Footer";

const HomePage = () => {

    return (
        <div>
            <div
                style={{
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'end'
                }}
            >

            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '87vh'
                }}
            >
                <img style={{height: '30vh'}} src='/img/logo.webp' alt='logo'/>
                <h1>Welcome to the Esport player Simulator !</h1>
                <Button label='Start Game' onClick={() => window.location.href = '/game'}/>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '10vh'
                }}
            >
                <Footer/>
            </div>
        </div>

    )
}

export default HomePage;