import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";

export const Header = () => {
    return (
        <Toolbar
            style={{backgroundColor: '#333', margin: '10px', padding: '0'}}
            start={
                <Button text style={{display: 'flex', alignItems: 'center'}} onClick={()=>{window.location.href = '/'}}>
                    <img src='/img/logo.webp' alt='logo' style={{height: '30px'}}/>
                    <span style={{marginLeft: '10px', color: '#BFBFBF', fontSize: '20px', fontWeight: 'lighter'}}>Esport player Simulator</span>
                </Button>
            }
        />
    )
}

export default Header