import React, {useState} from 'react'
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {QUESTIONS} from "../config/FormConfig";
import {TextsButtons} from "../config/TextsButtons";
import Header from "./Header";


const PlayerForm = () => {
    const [count, setCount] = useState(1);
    const [cashPrize, setCashPrize] = useState(0);
    const [selected, setSelected] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState({});
    const [answerHistory, setAnswerHistory] = useState([]);
    const [visibleHistory, setVisibleHistory] = useState(false);

    const questions = QUESTIONS

    const renderQuestion = (question) => {
        return (
            <div>
                <h2>{question.question}</h2>
                <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
                    {question.answers.map(answer => {
                        return (
                            <Button
                                style={{margin: '10px', width: '200px', height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}
                                key={answer.id}
                                label={answer.answer}
                                onClick={() => {
                                    setSelectedAnswer(answer)
                                }}
                                className={selectedAnswer.id === answer.id ? 'p-button-success' : ''}
                                disabled={selected}
                            >
                                {selectedAnswer.id === answer.id && (
                                    <i className="pi pi-check"></i>
                                )}
                                {(selectedAnswer.id === answer.id && selected) && (
                                    <div >
                                        <span><br/>&nbsp;{selectedAnswer.cashPrize} €</span>
                                    </div>
                                )}
                            </Button>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderButtons = () => {
        return (
            <div>
                <br/><br/>

                {!selected && (
                    <Button
                        type='submit'
                        severity='secondary'
                        label={TextsButtons.VALIDATE}
                        onClick={() => {
                            setSelected(true)
                            setCashPrize(cashPrize + selectedAnswer.cashPrize)
                            setAnswerHistory([...answerHistory, selectedAnswer])
                        }}
                        disabled={!selectedAnswer.id}
                    />
                )}

                {selected && (
                    <div>
                        <Button
                            label={TextsButtons.NEXT}
                            onClick={() => {
                                setCount(count + 1);
                                setSelected(false);
                                setSelectedAnswer({})
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }


    return (
        <>
            <Header/>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '25vh',
                }}
            >
                <h1>{TextsButtons.TITLE}</h1>

                {count <= questions.length && (
                    renderQuestion(questions[count - 1])
                )}

                {count <= questions.length && (
                    renderButtons(questions[count - 1])
                )}

                {count > questions.length && (
                    <div>
                        <h1>{TextsButtons.CONGRATS} {cashPrize} €</h1>

                        <Button
                            label={TextsButtons.RESTART}
                            onClick={() => {
                                setCount(1)
                                setCashPrize(0)
                                setSelected(false)
                                setSelectedAnswer({})
                                setAnswerHistory([])
                            }}
                        />
                    </div>
                )}

                {answerHistory.length > 0 && (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button
                            text raised
                            label={TextsButtons.HISTORY}
                            onClick={() => setVisibleHistory(!visibleHistory)}
                            severity='secondary'
                        >
                            &nbsp;
                            {visibleHistory ? <i className="pi pi-chevron-up"></i> :
                                <i className="pi pi-chevron-down"></i>}
                        </Button>
                    </div>
                )}

                {(answerHistory.length > 0 && visibleHistory) && (
                    <div>
                        <Divider/>
                        <h2>{TextsButtons.HISTORY}</h2>

                        <ul>
                            {answerHistory.map(answer => {
                                return (
                                    <li
                                        key={answer.id}
                                        style={{listStyleType: 'none'}}
                                    >
                                        {answer.answer} : {answer.cashPrize} €
                                    </li>
                                )
                            })}
                        </ul>

                        <h4>{TextsButtons.TOTAL} : {cashPrize} €</h4>
                    </div>
                )}
            </div>
        </>
    )
}

export default PlayerForm