export const QUESTIONS = [
    {
        id: 1,
        question: "Quel jeu choisis-tu pour ta carrière ?",
        answers: [
            {id: 1, answer: "League of legends", cashPrize: 20000},
            {id: 2, answer: "Fortnite", cashPrize: 15000},
            {id: 3, answer: "Counter-Strike", cashPrize: 25000} ,
            {id: 4, answer: "Rocket League", cashPrize: 10000}
        ]
    },
    {
        id: 2,
        question: "Quelle équipe ?",
        answers: [
            {id: 1, answer: "T1", cashPrize: 40000},
            {id: 2, answer: "Karmine Corp", cashPrize: 20000},
            {id: 3, answer: "Fnatic", cashPrize: 30000},
            {id: 4, answer: "G2 esports", cashPrize: 35000},
            {id: 5, answer: "Team Liquid", cashPrize: 25000},
            {id: 6, answer: "Ninjas in Pyjamas", cashPrize: 15000}
        ]
    },
    {
        id: 3,
        question: "Quel joueur es-tu ?",
        answers: [
            {id: 1, answer: "CANNA", cashPrize: 20000},
            {id: 2, answer: "VATIRA", cashPrize: 15000},
            {id: 3, answer: "BUGHA", cashPrize: 30000},
            {id: 4, answer: "SIMPLE", cashPrize: 35000},
            {id: 5, answer: "ZYWOO", cashPrize: 25000},
            {id: 6, answer: "FAKER", cashPrize: 40000}
        ]
    },
    {
        id: 4,
        question: "Quel est ton matériel ?",
        answers: [
            {id: 1, answer: "PC avec NVIDIA RTX 3080", cashPrize: 3000},
            {id: 2, answer: "PC avec INTEL INTEGRATED GRAPHICS", cashPrize: 500},
            {id: 3, answer: "PC avec NVIDIA GTX 1060", cashPrize: 1500}
        ]
    },
    {
        id: 5,
        question: "Quel est ton entrainement ?",
        answers: [
            {id: 1, answer: "Aimlab", cashPrize: 15000},
            {id: 2, answer: "Entrainement en jeu", cashPrize: 2000},
            {id: 3, answer: "Kovaak 2.0", cashPrize: 10000}
        ]
    },
    {
        id: 6,
        question: "Quelle est ta plateforme de streaming ?",
        answers: [
            {id: 1, answer: "Youtube Gaming", cashPrize: 10000},
            {id: 2, answer: "Twitch", cashPrize: 20000},
            {id: 3, answer: "Facebook", cashPrize: 2000},
            {id: 4, answer: "Kick", cashPrize: 5000}
        ]
    },
    {
        id: 7,
        question: "Quel pays choisis-tu ?",
        answers: [
            {id: 1, answer: "Chine", cashPrize: 20000},
            {id: 2, answer: "Corée du sud", cashPrize: 18000},
            {id: 3, answer: "France", cashPrize: 16000},
            {id: 4, answer: "Etats-Unis", cashPrize: 20000},
            {id: 5, answer: "Brésil", cashPrize: 16000},
            {id: 6, answer: "Canada", cashPrize: 14000}
        ]
    },
    {
        id: 8,
        question: "Quelle compétition choisis-tu ?",
        answers: [
            {id: 1, answer: "Rocket League World Championship", cashPrize: 18000},
            {id: 2, answer: "The International", cashPrize: 20000},
            {id: 3, answer: "Worlds - League Of Legends", cashPrize: 20000},
            {id: 4, answer: "Valorant Champions Tour", cashPrize: 16000}
        ]
    }
]