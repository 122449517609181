import './App.css';
import PlayerForm from "./Components/PlayerForm";
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import 'primereact/resources/themes/lara-dark-amber/theme.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import HomePage from "./Components/HomePage";
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
        <PrimeReactProvider>
            <PrimeReactContext.Provider value={{ ripple: true }}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/game" element={<PlayerForm />} />
                </Routes>
            </PrimeReactContext.Provider>
        </PrimeReactProvider>
    </div>
  );
}

export default App;
